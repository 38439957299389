/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Carousel, Row, Col, Card, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import IMG1 from './img/banner1.webp'
import IMG3 from './img/banner3.webp'
import IMG2 from './img/banner2.webp'
import Logo from './img/logo.png'

import axios from 'axios';

const host = "https://api.enfirad.com/"
class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            items: [],
        }



    }


    componentDidMount() {

        axios.get(`https://api.enfirad.com/dashbord/shop/items`)
            .then(resp => {
              
                this.setState({ items: resp.data.items })



            });



    }
    numberWithCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }

    render() {
        return (
            <div>
                <div className="App">

                    <main className="main">
                        <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>
                            <center>
                                <img width={350} height={150} src={Logo} alt="" />
                                <h3>انزل لارخص الاسعار</h3>
                            </center>
                            <section className="mt-60 mb-60">
                                <div className="container">
                                </div>
                            </section>

                        </div>

                    </main>
                </div>
                <Container>


                    <Row>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={IMG1}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    {/* <h3>First slide label</h3>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={IMG2}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    {/* <h3>First slide label</h3>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={IMG3}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    {/* <h3>First slide label</h3>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Row>
                    <Row style={{ margin: 20 }}>

                        <center> <h3>احدث المنتجات</h3>  </center>
                    </Row>
                    <Row className="justify-content-md-center">
                        {this.state.items.map((item, index) => (
                            <Col key={index} md="auto">
                                <Card style={{ width: 'auto', margin: 20 }} dir="rtl">
                                    <Card.Img variant="top" src={host + "files/" + item.main_photo} />
                                    <Card.Body>

                                        <Card.Title style={{ marginBottom: 15, fontWeight: "bold", color: "#46a3bf" ,fontStyle: "italic"}}>   {item.name} </Card.Title>
                                        <Card.Text style={{ marginBottom: 15, fontSize: 13 }}>
                                            {item.body}
                                        </Card.Text>
                                        <hr></hr>
                                        <Card.Title style={{ marginBottom: 15, fontWeight: "bold", color: "#46a3bf", textAlign: 'center' }}>  {this.numberWithCommas(item.price)} <span style={{fontSize:15,color:"#efab41" ,fontStyle: "italic"}}>شامل اجور التوصيل</span> </Card.Title>
                                        <hr></hr>
                                        <center> <Link to={`/item?item=${item.id}`}><Button variant="primary">اطلب الان</Button></Link></center>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}

                    </Row>
                </Container>
            </div>
        );
    }
}

export default Home;
