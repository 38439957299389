/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import Logo from './img/logo.png'
import { Link } from "react-router-dom";

class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            color: false,
            colo2: false,
            get_color2: '',
            name: '',
            gov: '',
            city: '',
            phone: '',
            size: '',
            s40: false,
            s1: false,
            s2: false,
            s3: false,
            s4: false,
        }
    }



    render() {
        return (
            <div className="App">

                <main className="main">
                    <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>
                        <center>
                              <img width={350} height={150} src={Logo} alt="" />
                            <h3>انزل لارخص الاسعار</h3>
                        </center>

                    </div>
                    <div className="col-12 col-lg-12" style={{padding:40}}>
                        <div className="single_product_desc">
                            <div className="product-meta-data" style={{textAlign: 'center'}}>

                                <a href="#">
                                    <h3 style={{marginBottom:10}}>شروط الخدمة </h3>
                                </a><div className="ratings-review mb-15 d-flex align-items-center justify-content-between">
                           

                                </div> 

                            </div>

                            <div >
                                <div style={{textAlign: 'right'}}>

                                    <p style={{textAlign: 'right', fontSize: 20}}>
                                        من خلال زيارة موقعنا و / أو شراء شيء منا ، فإنك تشارك في "الخدمة" الخاصة بنا
                                        وتوافق على الالتزام بالشروط والأحكام التالية ("شروط الخدمة" ، "الشروط") ، بما في
                                        ذلك تلك الشروط والأحكام والسياسات الإضافية المشار إليه هنا و / أو متاح من خلال
                                        ارتباط تشعبي. تنطبق شروط الخدمة هذه على جميع مستخدمي الموقع ، بما في ذلك علىمن
                                        خلال زيارة موقعنا و / أو شراء شيء منا ، فإنك تشارك في "الخدمة" الخاصة بنا وتوافق
                                        على الالتزام بالشروط والأحكام التالية ("شروط الخدمة" ، "الشروط") ، بما في ذلك
                                        تلك الشروط والأحكام والسياسات الإضافية المشار إليه هنا و / أو متاح من خلال
                                        ارتباط تشعبي. تنطبق شروط الخدمة هذه على جميع مستخدمي الموقع
                                        </p>
                                </div>
                                <div className="short_overview my-5">
                                    <div style={{textAlign: 'right'}}>
                                        <h4 style={{marginBottom:10}}>الشروط العامة </h4>

                                        <p style={{textAlign: 'right', fontSize: 20}}>
                                            بالموافقة على شروط الخدمة هذه ، فإنك تقر بأنك على الأقل تبلغ سن الرشد في
                                            ولايتك أو إقليم إقامتك ، أو أنك تبلغ سن الرشد في ولايتك أو إقليم إقامتك وأنك
                                            منحتنا موافقتك , لا يجوز لك استخدام منتجاتنا لأي غرض غير قانوني أو غير مصرح
                                            به ولا يجوز لك ، في استخدام الخدمة ، انتهاك أي قوانين في ولايتك القضائية
                                            (بما في ذلك على سبيل المثال لا الحصر قوانين حقوق النشر).
                                        </p>

                                    </div>
                                </div>
                                <div className="short_overview my-5">
                                    <div style={{textAlign: 'right'}}>
                                        <h4 style={{marginBottom:10}}>توفير المنتجات والتعديل على الاسعار </h4>
                                        <p style={{textAlign: 'right', fontSize: 20}}>قد تتوفر بعض المنتجات أو الخدمات
                                            حصريًا عبر الإنترنت من خلال موقع الويب. قد يكون لهذه المنتجات أو الخدمات
                                            كميات محدودة وتخضع للإرجاع أو الاستبدال فقط وفقًا لسياسة الإرجاع الخاصة بنا.
                                            نحن نحتفظ بالحق في أي وقت في تعديل أو إيقاف الخدمة (أو أي جزء أو محتوى منها)
                                            دون إشعار في أي وقت.
                                            لن نكون مسؤولين تجاهك أو تجاه أي طرف ثالث عن أي تعديل أو تغيير في الأسعار أو
                                            تعليق أو وقف للخدمة.


                                        </p>

                                    </div>
                                </div>
                                <div className="short_overview my-5">
                                    <div style={{textAlign: 'right'}}>
                                        <h4 style={{marginBottom:10}}>الأخطاء وعدم الدقة والسهو</h4>
                                        <p style={{textAlign: 'right', fontSize: 20}}>من حين لآخر ، قد تكون هناك
                                            معلومات على موقعنا أو في الخدمة تحتوي على أخطاء مطبعية أو عدم دقة أو سهو قد
                                            يتعلق بأوصاف المنتج والتسعير والعروض الترويجية والعروض ورسوم شحن المنتج
                                            وأوقات العبور والتوافر. نحتفظ بالحق في تصحيح أي أخطاء أو عدم دقة أو سهو ،
                                            وتغيير المعلومات أو تحديثها أو إلغاء الطلبات إذا كانت أي معلومات في الخدمة
                                            أو على أي موقع ويب ذي صلة غير دقيقة في أي وقت دون إشعار مسبق (بما في ذلك بعد
                                            تقديمك لطلبك) .


                                        </p>

                                    </div>
                                </div>
                                <div className="short_overview my-5">
                                    <div style={{textAlign: 'right'}}>
                                        <h4 style={{marginBottom:10}}>المعلومات الشخصية</h4>
                                        <p style={{textAlign: 'right', fontSize: 20}}>
                                            يخضع إرسالك للمعلومات الشخصية عبر المتجر لسياسة الخصوصية الخاصة بنا. لعرض سياسة الخصوصية الخاصة بنا.



                                        </p>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default Home;
